export const environment = {
  production: true,
  restRootUrl: 'https://api.faircheck.at',
  clientId: 'fairapp',
  redirectUrl: 'https://fairapp.faircheck.at/index.html',
  identityServer: 'https://identity.faircheck.at',
  fairmapUrl: 'https://fairmap.faircheck.at/index.html',
  vexportalBackend: 'https://clients.vex-portal.com/',
  vexportalWeb: 'https://app.vex-portal.com/info/fairVEX/',
  vexApps: 'https://apps.vex-portal.com/vex/'
};
